import React from "react";
import './Showcase.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };



const Showcase =() => {
return (
<div className = "ShowcaseContent">
<h1 className = "ShowcaseTitle"> Showcase</h1>
<center>
  <div className = "Carousel">
<Carousel
  swipeable={true}
  draggable={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlaySpeed={1000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
  <div className="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/_vMCFED6vKY" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
  <div className="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/1LCyl8KL2lE" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
  <div className="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/DXSCSlZaSm8" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
  <div className="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/CDP0IFcfyUw" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
  <div className="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/eDEMfNb9y0g" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>


  

</Carousel>;
</div>
</center>



</div>


)
}


export default Showcase;
