import React from "react";
import './Projects.css';
import MaskImage from './Mask.png';
import BooImage from './Boo.png';
import {Link} from "@reach/router";


const Projects =() => {
return (
<div className = "ProjectsContent">
<h1 className = "ProjectsTitle"> Projects</h1>

<div className = "Gallery">
<figure className = "ProjectTile" id = "BooProjectTile">
                <img src = {BooImage} className = "ProjectImage"/>
                <div className = "ProjectTitle"> <p> Boo! A Ghostly Tale </p></div>
                <div className = "Button"> <center> <Link path to = "/booproject"> Find Out More</Link> </center></div>
</figure>


<figure className = "ProjectTile" id = "MaskProjectTile">
                <img src = {MaskImage} className = "ProjectImage"/>
                <div className = "ProjectTitle"> <p> Masquerade! </p></div>
                <div className = "Button"> <center> <Link path to = "/masqueradeproject"> Find Out More</Link> </center></div>
        </figure>



</div>


</div>


)
}


export default Projects;
