import React from "react";
import './Home.css';
import {Link} from '@reach/router';




const Home =() => {
return (
<div className = "HomeContent">
<h1 className = "Hello"> Hello </h1>
<p className = "HomepageParagraph"> My name is Chidi, also known as Chidz. I am a game design graduate from Staffordshire University, specialising in animation. I graduated in 2019 with a 2:2. Whilst studying, I learned how to use 3D graphics modelling software such as 3DS Max and Maya, as well as implementing animations into game engines such as Unreal Engine. Currently, I am looking for a role in the games or entertainment industry to put my knowledge into practice. </p>
<div className = "ShowreelButton"> <Link to ="/showcase"> Showcase </Link></div>
</div>
)
}


export default Home;
