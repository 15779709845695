import React from "react";
import './Masquerade.css';



const MasqueradeProject =() => {
return (
<div className = "MasqueradeProject">
<h1> Masquerade! </h1>
<center>
<div className = "video">
<iframe width="560" height="315" src="https://www.youtube.com/embed/IB_wFCRZ2zY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
</center>
<h2> Introduction</h2>
<p> A robot invasion has taken over the city of Venice and has put a stop to the Venetian festival. 
    To save Venice and the festival, use your stealth and grappling hook to roam around the city of Venice.
    Collecting mask and restoring power back to the people saving the festival and stopping the robot invasion. </p>
<h2>About</h2>
<p> Masquerade is a first-person stealth set in Venice. This game is a student game design group project created for a collaborative game development and testing assignment at Staffordshire university.</p>
<h2> The Team </h2>
<p>
Alex Reynolds (Lead Designer) <br/>
Matthew Lamburn (Lead Tech Designer and QA Lead)<br/>
Calum Heffernan (Senior TechDesigner)<br/>
Emily Furniss (Lead Artist)<br/>
Ivan Terziev (Lead Artist)<br/>
Chidi Muomah (Lead Animator)<br/>
Alessandra Garber (Junior Designer)<br/>
Kian Jackson (Junior Designer)<br/>
Tom Coates (Junior Designer)<br/>
Josh Innes (JuniorTech Designer)<br/>
Ryan Thomason-Jones (JuniorArtist)<br/>
BorysUdodenko (JuniorArtist)<br/>
Serah Cassidy (Junior Artist)<br/>
Adam Williamson (JuniorArtist)<br/>
Josh Jackson (JuniorArtist)<br/>
Ryan Hill (Junior Artist)<br/>
Dan Somers (Junior Animator) <br/>

</p>
<h2> My Role </h2>
<p> My role during the project was lead animator. What I did to help my group was to plan out a list of animations required for the project. 
    The animations that were required was a walk cycle, run cycle, jump animation, attack animation, block animation and a special/unique animation (Which was the grappling hook). <br/><br/>
    After planning, I assigned all the animations between me and my junior animator. 
    I made the walk cycle, run cycle, jump cycle, and attack animation. My junior was left to do the grappling animation and block animation. 
    Once we established who was doing what, I reported the animations we will be designing and labelled out which animation cycle both of us are going to be create onto Jira.<br/><br/>
    Before the animations could begin, we needed a rig that was skinned to the character model. 
    I was in charge of constructing a rig for the 3D model as well as skinning it. Once the rigging and skinning was done, I asked my junior to go through the rig and skinning to check if it works and make any edits on areas I missed.<br/><br/>
    Another part of my task was checking if the animations flowed well in the Unreal Game Engine and the environment created. If it did not, I would go into 3DS Max and make the necessary changes needed for the project.
 </p>

</div>


)
}


export default MasqueradeProject;
