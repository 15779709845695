import React from "react";
import './Contact.css';




const Contact =() => {
return (
<div className = "ContactContent">
<h1 className = "Title"> Contact Me </h1>
<p className = "ContactParagraph"> Are you interested in working with me? Do you have a question? Fill out the form below and I will get back to you as soon as I can. </p>
<div className = "ContactForm"> 
<form name="contact" method="POST" data-netlify="true">
  <p>
    <label>Your Name: <input type="text" name="name" required /></label>   
  </p>
  <p>
    <label>Your Email: <input type="email" name="email"  required/></label>
  </p>
  <p>
    <label>Message: <textarea name="message" required></textarea></label>
  </p>
  <p>
    <button type="submit" className = "SubmitButton">Send</button>
  </p>
</form>


</div>
</div>
)
}


export default Contact;
