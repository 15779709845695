import React from 'react';
import './App.css';
import {Router} from '@reach/router';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Showreel from './pages/Showcase/Showcase';
import Contact from './pages/Contact/Contact';
import Footer from './components/Footer/Footer';
import Projects from './pages/Projects/Projects';
import BooProject from './pages/ProjectPages/Boo/BooPage';
import MasqueradeProject from './pages/ProjectPages/Masquerade/Masquerade';

function App() {
  return (
    <div>
      <Header/>

      <Router>
        <Home path = "/"/>
        <Showreel path = "/showcase"/>
        <Projects path = "/projects"/>
        <Contact path = "/contact"/> 
        <BooProject path = "/booproject"/>
        <MasqueradeProject path = "/masqueradeproject"/>
      </Router>
      <Footer/>
      
    </div>
  );
}

export default App;
