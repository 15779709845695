import React from "react";
import {Link } from "@reach/router";
import './Header.css';
import Logo from './logo.png';

const Header =() => {
    return (
        <div className = "HeaderDiv">
            <header>
            <Link to = "/">
                <img src = {Logo} alt = "Chidz logo" className = "logo"/>
            </Link>

            <nav>
                <ul>
                    <li> <Link to="/"> Home </Link></li>
                    <li> <Link to="/showcase"> Showcase</Link></li>
                    <li> <Link to="/projects"> Projects</Link></li>
                    <li> <Link to="/contact"> Contact</Link></li>
                </ul>

            </nav>

            </header>

        </div>
    )
}

export default Header;

