import React from "react";
import './BooPage.css';
import ReactPlayer from "react-player";



const BooProject =() => {
return (
<div className = "BooProject">
<h1> Boo! A Ghastly Tale!</h1>
<center>
<div className = "video">
<iframe width="560" height="315" src="https://www.youtube.com/embed/JuN15eSZBZk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
</center>
<h2> Introduction</h2>
<p> After an untimely passing, Boo the friendly ghost has returned home to finally rest peacefully. 
    But no! To Boo's surprise, his home has a new occupant (Sally). 
    Boo must scare her out of the house to regain his rightful property. 
    But Sally has grown suspicious of some paranormal activities in her home and has called in an Exorcist to drive you out of your own home, OH NO! 
    Boo must navigate around the house, moving Sally's possessions and interacting with the house to drive her mad, but be wary! 
    Do not get caught by the Exorcist or scare Sally so much, that she goes into cardiac arrest.</p>
<h2>About</h2>
<p> Boo! Ghostly tale! was a game design project that was created by my group during a 48-hour event called global games jam.</p>
<h2> The Team </h2>
<p>
Alex Reynolds (Designer/ Tech Designer) <br/>
Matthew Lamburn (Tech Designer) <br/>
Joseph Easter-Deed (Tech Designer)<br/>
Ivan Terziev (Artist)<br/>
Emily Furniss (Artist)<br/>
Ewan Thompson (Artist)<br/>
Ryan Thomason-Jones (Artist)<br/>
Conor Molloy (Animator)<br/>
Chidi Muomah (Animator)<br/>
</p>
<h2> My Role </h2>
<p> My role in my group was to design animations for sally who will be the human AI in our game. 
    Sally in the game will be roaming around in the environment trying to avoid the main character. 
    I also helped and assisted with other animation assets that where required for the game like the ghost who will be the main character and the exorcist who will be the enemy AI trying to take out the main character. </p>
<h2> The Process </h2>
<p>Before I started animating, I needed some form of skeleton inside the 3D character model so that I can move it and create animations from it. 
    What I did was, I froze the 3D character mesh in place using the freeze function in 3DS max and I created a CAT rig that matched the size of the character mesh. 
    Main reason why I froze the character mesh is, so I don`t edit it by mistake, while creating a rig inside the character. <br/>  <br/>
    After creating the rig, the model still would not be able to move.
    This was because even though the rig was made and matched the size of the model, it was not attached to it. 
    To fix this I needed to move onto the next step which was skinning. <br/> <br/>
    Skinning is the process where you get a 3D character model and bind its vertices to a joint setup or in this case, a rig. 
    Once the skinning was done if I moved any joint in the rig, the vertices of the character model that was close to the joint I moved, will move along with it. 
    This process was important because by doing it right, it will allow for animations to look normal and not distorted.<br/>  <br/>
    Next after skinning it was time to animate. Since we didn`t have much time, I was told to only design one animation cycle for the character that am working on. 
    Since I was working on animating sally, I decided to do a fast walk cycle. If I had more time, I would have added a more defined run cycle and a better walk cycle for the character. <br/> <br/>
    Lastly, after everything was done, I started sending the rig and the mesh without the animation over to Unreal Engine, to check what the model looks like in a game’s engine as well as getting the model set up in the environment created.
    Once everything was fine, I sent the rig with the 3d character mesh but this time with animation attached to it.
</p>
</div>


)
}


export default BooProject;
