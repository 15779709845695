import React from "react";
import {Link } from "@reach/router";
import './Footer.css';




const Footer =() => {
return (
<div className = "FooterDiv">
<footer>
    <div className = "footer">
        <div className = "rightfooter">
            <p> Website created by Ryan Nisbet <br/> rydesignstuff.co.uk</p>
        </div>

      
    </div>

    <div className = "bottomnav">
        <ul>
            <li>   <Link to = "/"> <i class="material-icons">home</i> </Link>   </li>
            <li>   <Link to = "/showcase"> <i class="material-icons">camera_roll</i> </Link>  </li>
            <li>   <Link to = "/projects"> <i class="material-icons">perm_media</i> </Link>  </li>
            <li>   <Link to = "/contact">  <i class="material-icons">contact_mail</i> </Link>  </li>
            
        </ul>
    </div>
</footer>
</div>
)
}


export default Footer;
